import React from 'react';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import { Box, TextField, IconButton, Tooltip } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import moment from 'moment';
import { addDaysToDate, formatDateToOnlyDate, getDateDifferenceInMS, getDayStart, subtractDaysFromDate } from '../utils/common';

interface Props {
  startDate: string | null | undefined,
  endDate: string | null | undefined,
  onChange: (startDate: any, endDate: any) => any,
  allowFutureDates?: boolean,
}

const DateRangeSelector: React.FC<Props> = ({
  startDate,
  endDate,
  allowFutureDates = false,
  onChange
}) => {
  const start = startDate ? Date.parse(startDate) : null;
  const end = endDate ? Date.parse(endDate) : null;
  const today = new Date().toISOString();

  const handleDateChange = (range: any) => {
    const [selectedStart, selectedEnd] = range;

    // Automatically set endDate to 15 days after the startDate
    if (selectedStart) {
      const newStartDate = moment(selectedStart).startOf('day');
      const newEndDate = allowFutureDates
        ? moment(newStartDate).add(7, 'days').endOf('day')
        : moment.min(moment(newStartDate).add(7, 'days').endOf('day'), moment().endOf('day'));

      onChange(newStartDate.toISOString(), newEndDate.toISOString());
    } else {
      onChange(null, null); // Reset if startDate is cleared
    }
  };

  const handlePreviousWeekClick = () => {
    // Subtract 7 days from the current startDate and set it to the previous Monday
    const newStartDate = moment(startDate).subtract(7, 'days').isoWeekday(1).startOf('day')
      .toISOString();
    // Add 7 days to the newStartDate to get the next Monday as the newEndDate and set the time to 23:59:59
    const newEndDate = moment(newStartDate).add(7, 'days').endOf('day').toISOString();

    onChange(newStartDate, newEndDate);
  };

  const handleNextWeekClick = () => {
    // Add 7 days to the current startDate and set it to the next Monday
    const newStartDate = moment(startDate).add(7, 'days').isoWeekday(1).startOf('day')
      .toISOString();
    // Add 7 days to the newStartDate to get the following Monday as the newEndDate and set the time to 23:59:59
    const newEndDate = moment(newStartDate).add(7, 'days').endOf('day').toISOString();

    onChange(newStartDate, newEndDate);
  };

  return (
    <div style={{ display: 'flex' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Tooltip title="Previous week">
          <IconButton color="primary" onClick={handlePreviousWeekClick}>
            <ChevronLeft />
          </IconButton>
        </Tooltip>
        <DateRangePicker
          startText="From"
          endText="To"
          value={[start, end]}
          onChange={handleDateChange}
          inputFormat="dd-MM-yyyy"
          renderInput={(startProps, endProps) => (
            <>
              <TextField sx={{ width: '6rem' }} {...startProps} variant="standard" color="secondary" />
              <Box sx={{ mx: 1 }} />
              <TextField sx={{ width: '6rem' }} {...endProps} variant="standard" color="secondary" />
            </>
          )}
          // maxDate={!allowFutureDates ? new Date() : undefined}
          calendars={1}
        />
        <Tooltip title="Next week">
          <IconButton color="primary" onClick={handleNextWeekClick}>
            <ChevronRight />
          </IconButton>
        </Tooltip>
      </LocalizationProvider>
    </div>
  );
};

export default DateRangeSelector;
