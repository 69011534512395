import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Button, IconButton, Box, Container, Card, CardHeader, Divider, CardContent, TextField, Grid, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Toolbar, Alert } from '@mui/material';
import BackIcon from '@mui/icons-material/ChevronLeft';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import { Coffee as CoffeeIcon, MessageSquare as MessageIcon } from 'react-feather';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ShiftsService from '../services/ShiftsService';
import SubmitFormButton from '../components/SubmitFormButton';
import AppConstants from '../constants/AppConstants';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage, setTimeInDate, formatInputDate, formatDateToLongDate, getDateDifference, getDateDifferenceInMS, getHourFromDate, getMinuteFromDate, getJobSiteName, sortByKey, convertToLocalDate } from '../utils/common';
import FormattedShift from '../models/FormattedShift';
import AddCommentModal from '../components/shift/AddCommentModal';
import ConfirmButton from '../components/ConfirmButton';
import { QuickShiftReq } from '../proxy/proxy';

interface Props {
  showAlert: (message: any) => void,
  clearAllAlerts: () => void,
  showBusyIndicator: () => void,
  hideBusyIndicator: () => void,
}

const SubmitShift: React.FC<Props> = ({
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const { id }: any = useParams();
  const navigate = useNavigate();

  const checkInType30Min = 64;
  const checkInTypePreCheck = 128;

  const shiftId = !Number.isNaN(Number(id)) ? Number(id) : 0;
  const [shiftData, setShiftData] = useState<FormattedShift>();
  const [breaks, setBreaks] = useState<Array<any>>([]);
  const [shiftCommentModal, setShiftCommentModal] = useState(false);
  const [shiftBreakModal, setShiftBreakModal] = useState(false);
  const [shiftStartDate, setShiftStartDate] = useState<string>();
  const [shiftEndDate, setShiftEndDate] = useState<string>();
  const [showAddCommentWarning, setShowAddCommentWarning] = useState(false);

  const refreshData = () => {
    const promises: Array<Promise<any>> = [
      ShiftsService.getShiftById(shiftId)
    ];

    showBusyIndicator();
    clearAllAlerts();
    Promise.all(promises)
      .then((result) => {
        setShiftData(result[0]);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };
  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    setBreaks((shiftData?.shiftBreaks ?? []));
  }, [shiftData]);

  const handleFormSubmit = (values: any) => {
    if (!!shiftData?.scheduledDrops && shiftData?.shiftComments.length === 0) {
      setShowAddCommentWarning(true);
      return;
    }

    showBusyIndicator();
    clearAllAlerts();
    const payload: QuickShiftReq = {
      startTimeLocal: convertToLocalDate(values.startDate, values.startHour, values.startMinute),
      endTimeLocal: convertToLocalDate(values.endDate, values.endHour, values.endMinute),
      endOdometerKms: values.endOdometerKms || null,
      shiftBreaks: breaks.length ? breaks.map(({ startedOnUTC, endedOnUTC }) => ({
        startTimeLocal: convertToLocalDate(startedOnUTC),
        endTimeLocal: convertToLocalDate(endedOnUTC)
      })) : undefined
    };

    ShiftsService.submitShift(shiftId, payload)
      .then(() => {
        showAlert(formatSuccessMessage('Shift Submitted successfully'));
        navigate(`/employee/shift/${shiftId}`);
      }).catch((err: any) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const handleCheckIn = () => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.preStart30MinuteCheckin(shiftId)
      .then((result) => {
        showAlert(formatSuccessMessage('Check In Successful'));
        setShiftData(result);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const handleAcceptShift = () => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.acceptShift(Number(id))
      .then((result) => {
        showAlert(formatSuccessMessage('You have accepted the shift'));
        setShiftData(result);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const back = () => {
    navigate(-1);
  };

  const handleAddShiftCommentSubmit = (drop: number, comment: string, isOTDComment: boolean) => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.addShiftComment(`${isOTDComment ? `Drop ${drop}` : 'Shift'}: ${comment}`, Number(id), isOTDComment ? 2 : 1)
      .then(() => {
        setShiftCommentModal(false);
        showAlert(formatSuccessMessage('Comment added successfully.'));
        refreshData();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };
  const ifStartDateBeforeEndDate = (values: any) => {
    const startDate = convertToLocalDate(values.startDate, values.startHour, values.startMinute) ?? new Date();
    const endDate = convertToLocalDate(values.endDate, values.endHour, values.endMinute) ?? new Date();
    return startDate < endDate;
  };

  const isDatesUnderWorkingHrs = (values: any) => {
    const startRange = new Date(shiftStartDate ?? shiftData?.scheduledStartUTC ?? '');
    const endRange = new Date(shiftEndDate ?? shiftData?.scheduledEndUTC ?? '');
    const startDate = new Date(convertToLocalDate(values.startDate, values.startHour, values.startMinute));
    const endDate = new Date(convertToLocalDate(values.endDate, values.endHour, values.endMinute));

    return startDate >= startRange && endDate <= endRange;
  };

  const getLastBreakEndDate = () => {
    // Check if there is a break taken before
    const previousBreak = breaks.length && breaks[breaks.length - 1];
    if (previousBreak) {
      return previousBreak.endedOnUTC;
    }

    // Calculate from shift start
    return shiftStartDate;
  };

  const getLastBreakBefore = (breakStartDate: string) => {
    // Check if there is a break taken before
    const lastBreakEnd = getLastBreakEndDate();

    // Calculate from shift start
    return Math.round(getDateDifferenceInMS(lastBreakEnd, breakStartDate, true) / 60000);
  };

  const hasBreakBreach = (values: any) => {
    if (!values.startDate) {
      return false;
    }
    const breakStartDate = convertToLocalDate(values.startDate, values.startHour, values.startMinute);
    const lastBreakBefore = getLastBreakBefore(breakStartDate?.toString() ?? '');

    return lastBreakBefore > AppConstants.BREAK_BREACH_TIME;
  };

  const hasMinimumBreakTime = (values: any) => {
    const difference = getDateDifferenceInMS(convertToLocalDate(values.startDate.toString() ?? '', values.startHour ?? 0, values.startMinute ?? 0)?.toString(), convertToLocalDate(values.endDate.toString() ?? '', values.endHour ?? 0, values.endMinute ?? 0)?.toString()) / 60000;
    return difference < 0 || difference >= AppConstants.BREAK_MINIMUM_TIME;
  };

  const handleAddShiftBreakSubmit = (values: any) => {
    if (!ifStartDateBeforeEndDate(values) || !isDatesUnderWorkingHrs(values) || hasBreakBreach(values) || !hasMinimumBreakTime(values)) {
      return;
    }

    setBreaks(
      [
        ...breaks,
        {
          startedOnUTC: convertToLocalDate(values.startDate, values.startHour, values.startMinute),
          endedOnUTC: convertToLocalDate(values.endDate, values.endHour, values.endMinute)
        }
      ].sort((a, b) => getDateDifferenceInMS(b.startedOnUTC, a.startedOnUTC, true))
    );
    setShiftBreakModal(false);
  };

  const deleteBreak = (index: number) => {
    const newList = [...breaks];

    newList.splice(index, 1);
    setBreaks(newList);
  };

  const navigateToStartShiftPage = () => {
    navigate(`/employee/shift/${id}/start?type=linfox`);
  };

  return (
    <>
      <Helmet>
        <title>Submit Shift | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              avatar={
                (
                  <IconButton aria-label="back" size="small" onClick={back}>
                    <BackIcon />
                  </IconButton>
                )
              }
              title="Submit Timesheet"
            />
            <Divider />
            <CardContent>
              <Grid container>
                <Grid item md={6} xs={12}>
                  <Typography variant="body1" margin="10px">
                    Vehicle: <b> {shiftData?.vehicle?.registrationNumber || `${shiftData?.unlistedVehicleRego} (Not listed)`}</b>
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="body1" margin="10px">
                    Vehicle Max Payload: <b> {shiftData?.vehicle?.maxCapacityInKgs ? `${shiftData?.vehicle?.maxCapacityInKgs} Kgs` : '--'}</b>
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="body1" margin="10px">
                    Store/Jobsite: <b>{getJobSiteName(shiftData?.jobsite)}</b>
                  </Typography>
                </Grid>
                {
                  shiftData?.scheduledStartUTC && (
                    <>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="body1"
                          margin="10px"
                        >
                          Check In Time: <b>{formatDateToLongDate(shiftData?.driverArrivalTimeLocal, true)}</b>
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography variant="body1" margin="10px">
                          Scheduled Total Work Hours: <b>{shiftData?.scheduledTotalWorkHours?.toFixed(2) ?? 0.00}</b>
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography variant="body1" margin="10px">
                          Scheduled Start: <b>{formatDateToLongDate(shiftData?.scheduledStartUTC, true)}</b>
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography variant="body1" margin="10px">
                          Scheduled End: <b>{formatDateToLongDate(shiftData?.scheduledEndUTC, true)}</b>
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography variant="body1" margin="10px">
                          Scheduled Weight: <b>{shiftData?.scheduledWeight ?? 0} kg</b>
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography variant="body1" margin="10px">
                          Scheduled Kms: <b>{shiftData?.scheduledKms ?? 0} kms</b>
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography variant="body1" margin="10px">
                          Scheduled Drops: <b>{shiftData?.scheduledDrops ?? 0}</b>
                        </Typography>
                      </Grid>

                    </>
                  )
                }
                {
                  shiftData?.instructions && (
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        margin="10px"
                      >
                        Instructions: <b>{shiftData?.instructions}</b>
                      </Typography>
                    </Grid>
                  )
                }
              </Grid>
              <Divider />
              {
                !shiftData?.userHasAccepted && (
                  <Container maxWidth="xs">
                    <Box sx={{ py: 2 }}>
                      <ConfirmButton
                        data-auto-id="acceptConfirmButton"
                        buttonLabel="Accept"
                        dialogTitle="Accept Shift"
                        showCancelButton
                        message="Your are about to accept the shift."
                        onConfirm={handleAcceptShift}
                        fullWidth
                      />
                    </Box>
                  </Container>
                )
              }
              {
                shiftData?.userHasAccepted && !shiftData?.checkins?.find((checkin) => checkin.checkinType === checkInType30Min) && (
                  <Container maxWidth="xs">
                    <Box sx={{ py: 2 }}>
                      <Button fullWidth sx={{ marginTop: 2 }} color="primary" onClick={handleCheckIn} variant="contained">
                        Check In
                      </Button>
                    </Box>
                  </Container>
                )
              }
              {
                shiftData?.checkins?.find((checkin) => checkin.checkinType === checkInType30Min) && !shiftData?.checkins?.find((checkin) => checkin.checkinType === checkInTypePreCheck) && (
                  <Container maxWidth="xs">
                    <Box sx={{ py: 2 }}>
                      <Button
                        fullWidth sx={{ marginTop: 2 }} color="primary" variant="contained"
                        onClick={navigateToStartShiftPage}
                      >
                        Precheck
                      </Button>
                    </Box>
                  </Container>
                )
              }
              {
                shiftData?.checkins?.find((checkin) => checkin.checkinType === checkInType30Min) && shiftData?.checkins?.find((checkin) => checkin.checkinType === checkInTypePreCheck) && (
                  <Formik
                    initialValues={{
                      startDate: formatInputDate(shiftData?.scheduledStartUTC, true),
                      startHour: getHourFromDate(shiftData?.scheduledStartUTC, true) || 0,
                      startMinute: getMinuteFromDate(shiftData?.scheduledStartUTC, true) || 0,
                      endDate: formatInputDate(shiftData?.scheduledEndUTC, true),
                      endHour: getHourFromDate(shiftData?.scheduledEndUTC, true) || 0,
                      endMinute: getMinuteFromDate(shiftData?.scheduledEndUTC, true) || 0,
                      endOdometerKms: ''
                    }}
                    validationSchema={Yup.object().shape({
                      startDate: Yup.date().required('Start Date is required'),
                      endDate: Yup.date()
                        .required('Required')
                        .min(Yup.ref('startDate'), 'End Date Time should be greater than Start Date Time')
                        .when(['startDate', 'startHour', 'startMinute', 'endHour', 'endMinute'], {
                          is: (startDate, startHour, startMinute, endHour, endMinute) => (startDate && !Number.isNaN(startHour) && !Number.isNaN(startMinute) && !Number.isNaN(endHour) && !Number.isNaN(endMinute)),
                          then: Yup.date().test('minTime', 'End Date Time should be greater than Start Date Time', function (value) {
                            return new Date(setTimeInDate(this.parent.startDate, this.parent.startHour, this.parent.startMinute)) < new Date(setTimeInDate(value, this.parent.endHour, this.parent.endMinute));
                          })
                        }),
                      startHour: Yup.number().required('Required'),
                      startMinute: Yup.number().required('Required'),
                      endHour: Yup.number().required('Required'),
                      endMinute: Yup.number(),
                      endOdometerKms: Yup.number().min(shiftData?.startOdometerKms || 0)
                    })}
                    onSubmit={handleFormSubmit}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                      touched,
                      values,
                      dirty,
                      isValid
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                          {
                            dirty && !ifStartDateBeforeEndDate(values) && (
                              <Grid item xs={12}>
                                <Alert severity="error">End Date Time should be greater than Start Date Time</Alert>
                              </Grid>
                            )
                          }
                          <Grid item md={2} xs={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                inputFormat="dd-MM-yyyy"
                                label="Start date"
                                value={values.startDate}
                                onChange={(newValue: any) => setFieldValue('startDate', formatInputDate(newValue, true))}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={Boolean(touched.startDate && errors.startDate)}
                                    fullWidth
                                    helperText={touched.startDate && errors.startDate}
                                    margin="normal"
                                    name="startDate"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    required
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item md={2} xs={4}>
                            <TextField
                              fullWidth
                              label="Hour"
                              margin="normal"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              select
                              InputLabelProps={{ shrink: true }}
                              value={values.startHour}
                              name="startHour"
                              variant="outlined"
                              error={Boolean(touched.startHour && errors.startHour)}
                              helperText={touched.startHour && errors.startHour}
                              SelectProps={{ native: true }}
                            >
                              {Array.from(Array(24).keys()).map((option) => (
                                <option
                                  key={option}
                                  value={option}
                                >
                                  {String(option).padStart(2, '0')}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item md={2} xs={4}>
                            <TextField
                              fullWidth
                              label="Minute"
                              margin="normal"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              select
                              InputLabelProps={{ shrink: true }}
                              value={values.startMinute}
                              name="startMinute"
                              variant="outlined"
                              error={Boolean(touched.startMinute && errors.startMinute)}
                              helperText={touched.startMinute && errors.startMinute}
                              SelectProps={{ native: true }}
                            >
                              {Array.from(Array(60).keys()).map((option) => (
                                <option
                                  key={option}
                                  value={option}
                                >
                                  {String(option).padStart(2, '0')}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item md={2} xs={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                inputFormat="dd-MM-yyyy"
                                label="End date"
                                value={values.endDate}
                                onChange={(newValue) => setFieldValue('endDate', formatInputDate(newValue, true))}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={Boolean(touched.endDate && errors.endDate)}
                                    fullWidth
                                    helperText={touched.endDate && errors.endDate}
                                    margin="normal"
                                    name="endDate"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    required
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item md={2} xs={4}>
                            <TextField
                              fullWidth
                              label="Hour"
                              margin="normal"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              select
                              InputLabelProps={{ shrink: true }}
                              value={values.endHour}
                              name="endHour"
                              variant="outlined"
                              error={Boolean(touched.endHour && errors.endHour)}
                              helperText={touched.endHour && errors.endHour}
                              SelectProps={{ native: true }}
                            >
                              {Array.from(Array(24).keys()).map((option) => (
                                <option
                                  key={option}
                                  value={option}
                                >
                                  {String(option).padStart(2, '0')}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item md={2} xs={4}>
                            <TextField
                              fullWidth
                              label="Minute"
                              margin="normal"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              select
                              InputLabelProps={{ shrink: true }}
                              value={values.endMinute}
                              name="endMinute"
                              variant="outlined"
                              error={Boolean(touched.endMinute && errors.endMinute)}
                              helperText={touched.endMinute && errors.endMinute}
                              SelectProps={{ native: true }}
                            >
                              {Array.from(Array(60).keys()).map((option) => (
                                <option
                                  key={option}
                                  value={option}
                                >
                                  {String(option).padStart(2, '0')}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.endOdometerKms && errors.endOdometerKms)}
                              fullWidth
                              helperText={touched.endOdometerKms && errors.endOdometerKms}
                              label="End Odometer Kms"
                              margin="normal"
                              name="endOdometerKms"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.endOdometerKms}
                              variant="outlined"
                              type="number"
                              InputLabelProps={{ shrink: true }}
                              placeholder={`Started at ${shiftData.startOdometerKms} kms`}
                            />
                          </Grid>
                        </Grid>
                        <Toolbar style={{ justifyContent: 'space-between' }}>
                          <Typography variant="h4" margin="10px" paddingTop="10px">
                            Breaks
                          </Typography>
                        </Toolbar>
                        {
                          !!breaks.length && breaks.map((row, index) => (
                            <Card key={row.id} sx={{ marginBottom: '10px' }} variant="outlined">
                              <CardHeader
                                avatar={<CoffeeIcon />}
                                title={formatDateToLongDate(row.startedOnUTC, true)}
                                subheader={row.endedOnUTC ? `Duration: ${getDateDifference(row.startedOnUTC, row.endedOnUTC, true)}` : 'Ongoing'}
                                action={
                                  (
                                    <IconButton color="primary" onClick={() => deleteBreak(index)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  )
                                }
                              />
                            </Card>
                          ))
                        }
                        {
                          !breaks.length && (
                            <>
                              <Divider />
                              <Typography
                                variant="h4"
                                margin="10px"
                                paddingTop="10px"
                                align="center"
                              >
                                No Breaks taken
                              </Typography>
                            </>
                          )
                        }
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={
                              () => {
                                setShiftStartDate(convertToLocalDate(values.startDate, values.startHour, values.startMinute)?.toString());
                                setShiftEndDate(convertToLocalDate(values.endDate, values.endHour, values.endMinute)?.toString());
                                setShiftBreakModal(true);
                              }
                            }
                          >
                            Add Break
                          </Button>
                        </div>
                        <Toolbar style={{ justifyContent: 'space-between' }}>
                          <Typography variant="h4" margin="10px" paddingTop="10px">
                            Comments *
                          </Typography>
                        </Toolbar>
                        {
                          sortByKey(shiftData?.shiftComments || [], 'text').map((row) => (
                            <Card key={row.id} sx={{ marginBottom: '10px' }} variant="outlined">
                              <CardHeader
                                avatar={<MessageIcon />}
                                title={row.text}
                              />
                            </Card>
                          ))
                        }
                        {
                          !shiftData?.shiftComments?.length && (
                            <>
                              <Divider />
                              {
                                showAddCommentWarning && (
                                  <Alert severity="error">Comments should be entered</Alert>
                                )
                              }
                              <Typography
                                variant="h4"
                                margin="10px"
                                paddingTop="10px"
                                align="center"
                              >
                                No Comments entered
                              </Typography>
                            </>
                          )
                        }
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
                          <Button color="primary" variant="outlined" onClick={() => setShiftCommentModal(true)}>
                            Add Comment
                          </Button>
                        </div>
                        <Divider />
                        <Container maxWidth="xs">
                          <Box sx={{ py: 2 }}>
                            <SubmitFormButton
                              dirty
                              isValid={isValid && ((shiftData?.shiftComments?.length > 0) === (shiftData?.scheduledDrops > 0))}
                              onConfirm={handleSubmit}
                              onInvalidClick={() => {
                                if (shiftData?.scheduledDrops && shiftData?.shiftComments?.length) {
                                  setShowAddCommentWarning(true);
                                }
                              }}
                              buttonLabel="Submit"
                              message="Shift will be submitted and cannot be reverted."
                              fullWidth
                              alwaysEnabled
                            />
                            <Button fullWidth sx={{ marginTop: 2 }} color="secondary" onClick={back} variant="outlined">
                              Cancel
                            </Button>
                          </Box>
                        </Container>
                      </form>
                    )}
                  </Formik>
                )
              }
            </CardContent>
          </Card>
        </Container>
      </Box>
      {
        (shiftStartDate || shiftData?.scheduledStartUTC) && (
          <Dialog
            open={shiftBreakModal}
            onClose={(_, reason) => {
              if (reason !== 'backdropClick') {
                setShiftBreakModal(false);
              }
            }}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Add Break</DialogTitle>
            <Formik
              initialValues={{
                startDate: formatInputDate(getLastBreakEndDate(), true),
                startHour: 0,
                startMinute: 0,
                endDate: formatInputDate(getLastBreakEndDate(), true),
                endHour: 0,
                endMinute: 0
              }}
              validationSchema={Yup.object().shape({
                startDate: Yup.date().required('Required'),
                endDate: Yup.date().required('Required'),
                startHour: Yup.number().required('Required'),
                startMinute: Yup.number().required('Required'),
                endHour: Yup.number().required('Required'),
                endMinute: Yup.number()
              })}
              onSubmit={handleAddShiftBreakSubmit}
            >
              {
                ({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  touched,
                  values,
                  dirty,
                  isValid
                }) => (
                  <form onSubmit={handleSubmit}>
                    <DialogContent>
                      <Grid container spacing={3}>
                        {
                          dirty && isValid && hasBreakBreach(values) && (
                            <Grid item xs={12}>
                              <Alert severity="error">Break should be taken within 5 hours of driving time</Alert>
                            </Grid>
                          )
                        }
                        {
                          dirty && isValid && !hasMinimumBreakTime(values) && (
                            <Grid item xs={12}>
                              <Alert severity="error">Break should be at least 30mins</Alert>
                            </Grid>
                          )
                        }
                        {
                          dirty && !ifStartDateBeforeEndDate(values) && (
                            <Grid item xs={12}>
                              <Alert severity="error">End Date Time should be greater than Start Date Time</Alert>
                            </Grid>
                          )
                        }
                        {
                          dirty && !isDatesUnderWorkingHrs(values) && (
                            <Grid item xs={12}>
                              <Alert severity="error">Start and End Date Times should be within work hours</Alert>
                            </Grid>
                          )
                        }
                        <Grid item xs={4}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              inputFormat="dd-MM-yyyy"
                              label="Start date"
                              value={values.startDate}
                              onChange={(newValue: any) => setFieldValue('startDate', formatInputDate(newValue, true))}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(touched.startDate && errors.startDate)}
                                  fullWidth
                                  helperText={touched.startDate && errors.startDate}
                                  margin="normal"
                                  name="startDate"
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  required
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            label="Hour"
                            margin="normal"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            select
                            InputLabelProps={{ shrink: true }}
                            value={values.startHour}
                            name="startHour"
                            variant="outlined"
                            error={Boolean(touched.startHour && errors.startHour)}
                            helperText={touched.startHour && errors.startHour}
                            SelectProps={{ native: true }}
                          >
                            {Array.from(Array(24).keys()).map((option) => (
                              <option
                                key={option}
                                value={option}
                              >
                                {String(option).padStart(2, '0')}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            label="Minute"
                            margin="normal"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            select
                            InputLabelProps={{ shrink: true }}
                            value={values.startMinute}
                            name="startMinute"
                            variant="outlined"
                            error={Boolean(touched.startMinute && errors.startMinute)}
                            helperText={touched.startMinute && errors.startMinute}
                            SelectProps={{ native: true }}
                          >
                            {Array.from(Array(60).keys()).map((option) => (
                              <option
                                key={option}
                                value={option}
                              >
                                {String(option).padStart(2, '0')}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={4}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              inputFormat="dd-MM-yyyy"
                              label="End date"
                              value={values.endDate}
                              onChange={(newValue) => setFieldValue('endDate', formatInputDate(newValue, true))}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(touched.endDate && errors.endDate)}
                                  fullWidth
                                  helperText={touched.endDate && errors.endDate}
                                  margin="normal"
                                  name="endDate"
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  required
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            label="Hour"
                            margin="normal"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            select
                            InputLabelProps={{ shrink: true }}
                            value={values.endHour}
                            name="endHour"
                            variant="outlined"
                            error={Boolean(touched.endHour && errors.endHour)}
                            helperText={touched.endHour && errors.endHour}
                            SelectProps={{ native: true }}
                          >
                            {Array.from(Array(24).keys()).map((option) => (
                              <option
                                key={option}
                                value={option}
                              >
                                {String(option).padStart(2, '0')}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            label="Minute"
                            margin="normal"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            select
                            InputLabelProps={{ shrink: true }}
                            value={values.endMinute}
                            name="endMinute"
                            variant="outlined"
                            error={Boolean(touched.endMinute && errors.endMinute)}
                            helperText={touched.endMinute && errors.endMinute}
                            SelectProps={{ native: true }}
                          >
                            {Array.from(Array(60).keys()).map((option) => (
                              <option
                                key={option}
                                value={option}
                              >
                                {String(option).padStart(2, '0')}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center' }}>
                      <Button
                        onClick={() => setShiftBreakModal(false)}
                        color="primary"
                        variant="outlined"
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                      >
                        Add
                      </Button>
                    </DialogActions>
                  </form>
                )
              }
            </Formik>
          </Dialog>
        )
      }
      {
        shiftData?.shiftStatusId === 2 && (
          <AddCommentModal
            comments={shiftData?.shiftComments}
            addComment={handleAddShiftCommentSubmit}
            drops={shiftData?.scheduledDrops ?? 0}
            isOpen={shiftCommentModal}
            closeModal={() => {
              setShiftCommentModal(false);
            }}
          />
        )
      }
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitShift);
