import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Button, IconButton, Box, Container, Card, CardHeader, Divider, CardContent, TextField, Grid, CardActions, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, ListItemText, Typography } from '@mui/material';
import BackIcon from '@mui/icons-material/ChevronLeft';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { sortBy } from 'underscore';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import StaffService from '../services/StaffService';
import ShiftsService from '../services/ShiftsService';
import StoresService from '../services/StoresService';
import VehiclesService from '../services/VehiclesService';
import EnumService from '../services/EnumService';
import SubmitFormButton from '../components/SubmitFormButton';
import AppConstants from '../constants/AppConstants';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage, formatInputDate, setTimeInDate, subtractDaysFromDate, getHourFromDate, getMinuteFromDate, getJobSiteName, getDateDifferenceInMS, convertToLocalDate } from '../utils/common';
import FormattedShift from '../models/FormattedShift';
import FormattedStaff from '../models/FormattedStaff';
import FormattedVehicle from '../models/FormattedVehicle';
import { RootState } from '../store/store';
import { CompanyRes, JobsiteRes } from '../proxy/proxy';

interface Props {
  companyProfile: CompanyRes | null,
  showAlert: (message: any) => void,
  clearAllAlerts: () => void,
  showBusyIndicator: () => void,
  hideBusyIndicator: () => void,
}

const EditShift: React.FC<Props> = ({
  companyProfile,
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const { id }: any = useParams();
  const navigate = useNavigate();

  const [shiftId, setShiftId] = useState<number>();
  const [selectCompanyModal, setSelectCompanyModal] = useState(false);
  const [extCompanyId, setExtCompanyId] = useState<number>(0);
  const [shiftData, setShiftData] = useState<FormattedShift>();
  const [allStaffs, setAllStaffs] = useState<Array<FormattedStaff>>([]);
  const [allVehicles, setAllVehicles] = useState<Array<FormattedVehicle>>([]);
  const [allStores, setAllStores] = useState<Array<JobsiteRes>>([]);
  const [externalCompanies, setExternalCompanies] = useState<Array<any>>([]);
  const [breachCalculationTypes, setBreachCalculationTypes] = useState<Array<any>>([]);
  const [allStates, setAllStates] = useState<Array<any>>([]);
  const [scheduledKm, setNum] = useState('');

  const handleNumChange = (event: any) => {
    const limit = 7;
    setNum(event.target.value.slice(0, limit));
  };

  useEffect(() => {
    const promises: Array<Promise<any>> = [
      EnumService.getAllEnums(),
      StaffService.getAllStaffs(),
      StoresService.getAllStores(),
      VehiclesService.getAllVehicles()
    ];
    if (!Number.isNaN(Number(id))) {
      setShiftId(Number(id));
      promises.push(ShiftsService.getShiftById(id));
    } else {
      setSelectCompanyModal(true);
    }

    showBusyIndicator();
    clearAllAlerts();
    Promise.all(promises)
      .then((result) => {
        setExternalCompanies(result?.[0]?.extCompanies);
        setBreachCalculationTypes(result?.[0]?.breachCalculationTypes);

        const drivers = sortBy(result?.[1] || [], (i) => i.name?.toLowerCase());
        const stores = sortBy(result?.[2] || [], (i) => i.name?.toLowerCase());
        const vehicles = sortBy(result?.[3] || [], (i) => i.registrationNumber?.toLowerCase());
        const states = sortBy(result?.[0].states || [], (i) => i.value?.toLowerCase());
        setAllStaffs(drivers);
        setAllStores(stores);
        setAllVehicles(vehicles);
        setAllStates(states);

        if (!Number.isNaN(Number(id))) {
          setShiftData(result?.[4]);
        } else {
          setShiftData(new FormattedShift());
        }
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  }, []);

  const calculateScheduledTotalWorkHours = (values: any) => {
    const scheduledStart = setTimeInDate(values.scheduledStartDate, values.scheduledStartHour, values.scheduledStartMinute, true);
    const scheduledEnd = setTimeInDate(values.scheduledEndDate, values.scheduledEndHour, values.scheduledEndMinute, true);

    const diffInMS = getDateDifferenceInMS(scheduledStart, scheduledEnd, true);

    if (!diffInMS) return 0;

    const diffInHrs = diffInMS / 1000 / 60 / 60;

    return Number(diffInHrs.toFixed(2));
  };

  const handleFormSubmit = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();
    const payload: any = {
      userId: values.userId ? Number(values.userId) : null,
      extDriverId: values.extDriverId,
      routeId: null,
      unlistedVehicleRego: Number(values.vehicleId) === 0 ? values.unlistedVehicleRego : null,
      startTimeLocal: convertToLocalDate(values.scheduledStartDate, values.scheduledStartHour, values.scheduledStartMinute),
      endTimeLocal: convertToLocalDate(values.scheduledEndDate, values.scheduledEndHour, values.scheduledEndMinute),
      scheduledWeight: Number(values.scheduledWeight),
      scheduledKms: Number(values.scheduledKms),
      // scheduledKms: Number(scheduledKm),
      scheduledDrops: Number(values.scheduledDrops),
      scheduledDifficulty: 0,
      scheduledTotalWorkHours: calculateScheduledTotalWorkHours(values),
      instructions: values.instructions,
      breachCalculatedUsing: Number(values?.extCompanyId ?? 0) === companyProfile?.id ? Number(values.breachCalculatedUsing) : 0,
      vehicleId: Number(values.vehicleId) || null,
      jobsiteId: Number(values.jobsiteId) || null,
      // userId: Number(values?.userId) || null,
      jobsite: null,
      extCompanyId: Number(values?.extCompanyId ?? 0) !== companyProfile?.id ? Number(values.extCompanyId) : null,
      dock: values?.dock
    };
    if (!payload.jobsiteId) {
      payload.jobsite = {
        name: values.jobsiteName,
        address: {
          street: values.jobsiteStreet,
          areaCode: values.jobsiteAreaCode?.toString()?.padStart(4, '0') || null,
          suburb: values.jobsiteSuburb,
          city: values.jobsiteCity,
          stateId: Number(values.jobsiteStateId)
        }
      };
    }
    const promise = shiftId ? ShiftsService.updateShift(shiftId, payload) : ShiftsService.scheduleShift(payload);

    promise
      .then((response) => {
        if (shiftId) {
          showAlert(formatSuccessMessage('Shift updated successfully'));
          navigate(`/app/shift/list/${shiftId}`);
        } else {
          showAlert(formatSuccessMessage('Shift scheduled successfully'));
          navigate(`/app/shift/list/${response.id}`);
        }
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const back = () => {
    navigate(-1);
  };

  return (
    <>
      <Helmet>
        <title>{id ? 'Edit Shift' : 'Schedule A Shift'} | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              avatar={
                (
                  <IconButton aria-label="back" size="small" onClick={back}>
                    <BackIcon />
                  </IconButton>
                )
              }
              title={id ? 'Edit Shift' : 'Schedule A Shift'}
            />
            <Divider />
            <CardContent>
              {
                (!!shiftId || !!extCompanyId) && !!shiftData && (
                  <Formik
                    initialValues={{
                      userId: shiftData?.user?.id || '',
                      vehicleId: shiftData?.unlistedVehicleRego ? 0 : shiftData?.vehicle?.id,
                      unlistedVehicleRego: shiftData?.unlistedVehicleRego || '',
                      extDriverId: shiftData?.userExtDriverId || '',
                      jobsiteId: shiftData?.jobsite?.id || '',
                      jobsiteName: shiftData?.jobsite?.name || '',
                      jobsiteStreet: shiftData?.jobsite?.address?.street || '',
                      jobsiteSuburb: shiftData?.jobsite?.address?.suburb || '',
                      jobsiteCity: shiftData?.jobsite?.address?.city || '',
                      jobsiteAreaCode: shiftData?.jobsite?.address?.areaCode || '',
                      jobsiteStateId: shiftData?.jobsite?.address?.stateId || '',
                      scheduledStartDate: formatInputDate(shiftData?.scheduledStartUTC, true),
                      scheduledStartHour: getHourFromDate(shiftData?.scheduledStartUTC, true) || 0,
                      scheduledStartMinute: getMinuteFromDate(shiftData?.scheduledStartUTC, true) || 0,
                      scheduledEndDate: formatInputDate(shiftData?.scheduledEndUTC, true),
                      scheduledEndHour: getHourFromDate(shiftData?.scheduledEndUTC, true) || 0,
                      scheduledEndMinute: getMinuteFromDate(shiftData?.scheduledEndUTC, true) || 0,
                      scheduledWeight: shiftData?.scheduledWeight || 0,
                      scheduledKms: shiftData?.scheduledKms || 0,
                      scheduledDrops: shiftData?.scheduledDrops || 0,
                      scheduledTotalWorkHours: shiftData?.scheduledTotalWorkHours ? Number(shiftData.scheduledTotalWorkHours)?.toFixed(2) : 0,
                      instructions: shiftData?.instructions || '',
                      extCompanyId: shiftData?.extCompany?.id || extCompanyId || companyProfile?.id,
                      breachCalculatedUsing: shiftData?.breachCalculatedUsing?.toString() || 0,
                      dock: shiftData?.dock
                    }}
                    validationSchema={Yup.object().shape({
                      userId: Yup.number(),
                      extDriverId: Yup.string(),
                      vehicleId: Yup.number()
                        .min(0)
                        .required('Please select Vehicle'),
                      unlistedVehicleRego: Yup.string()
                        .when('vehicleId', {
                          is: 0,
                          then: Yup.string().required('Vehicle rego is required for not listed vehicles')
                        }),
                      scheduledStartDate: Yup.date()
                        .min(subtractDaysFromDate(setTimeInDate(new Date(), 0, 0, true), 1), 'Start Date should not be past date')
                        .required('Scheduled Start Date is required'),
                      scheduledEndDate: Yup.date()
                        .required('Scheduled End Date is required')
                        .min(Yup.ref('scheduledStartDate'), 'Scheduled End Date should be greater than Scheduled Start Date')
                        .when(['scheduledStartDate', 'scheduledStartHour', 'scheduledStartMinute', 'scheduledEndHour', 'scheduledEndMinute'], {
                          is: (scheduledStartDate, scheduledStartHour, scheduledStartMinute, scheduledEndHour, scheduledEndMinute) => (scheduledStartDate && !Number.isNaN(scheduledStartHour) && !Number.isNaN(scheduledStartMinute) && !Number.isNaN(scheduledEndHour) && !Number.isNaN(scheduledEndMinute)),
                          then: Yup.date().test('minTime', 'Scheduled End Date should be greater than Scheduled Start Date', function (value) {
                            return new Date(setTimeInDate(this.parent.scheduledStartDate, this.parent.scheduledStartHour, this.parent.scheduledStartMinute, true)) < new Date(setTimeInDate(value, this.parent.scheduledEndHour, this.parent.scheduledEndMinute, true));
                          })
                        }),
                      scheduledStartHour: Yup.number().required('Required'),
                      scheduledStartMinute: Yup.number().required('Required'),
                      scheduledEndHour: Yup.number()
                        .required('Required'),
                      scheduledEndMinute: Yup.number()
                        .required('Required'),
                      jobsiteId: Yup.number()
                        .min(1)
                        .required('Please select Jobsite'),
                      jobsiteName: Yup.string()
                        .when('jobsiteId', {
                          is: 0,
                          then: Yup.string().required('Name is required for not listed Jobsite')
                        }),
                      jobsiteStreet: Yup.string(),
                      jobsiteSuburb: Yup.string(),
                      jobsiteCity: Yup.string(),
                      jobsiteAreaCode: Yup.number()
                        .when('jobsiteId', {
                          is: 0,
                          then: Yup.number().min(0, 'Post Code should be between 0000 and 9999').max(9999, 'Post Code should be between 0000 and 9999').required('Post Code is required')
                        }),
                      jobsiteStateId: Yup.string()
                        .when('jobsiteId', {
                          is: 0,
                          then: Yup.string().required('State is required for not listed Jobsite')
                        }),
                      scheduledWeight: Yup.number()
                        .min(0, 'Scheduled Weight must be greater than or equal to 0')
                        .test(
                          'max-capacity',
                          'Scheduled Weight should not exceed the vehicle\'s maximum payload capacity',
                          function (value) {
                            const { vehicleId } = this.parent;
                            const selectedVehicle = allVehicles.find((vehicle) => vehicle.id === vehicleId);
                            if (selectedVehicle?.maxCapacityInKgs !== null && selectedVehicle?.maxCapacityInKgs !== undefined && value) {
                              return value <= selectedVehicle.maxCapacityInKgs;
                            }
                            return true; // No max capacity constraint
                          }
                        ),
                      scheduledKms: Yup.number().min(0),
                      scheduledDrops: Yup.number().min(0),
                      scheduledTotalWorkHours: Yup.number().min(0),
                      instructions: Yup.string(),
                      extCompanyId: Yup.number(),
                      dock: Yup.string(),
                      breachCalculatedUsing: Yup.number().min(0)
                    })}
                    onSubmit={handleFormSubmit}
                  >
                    {({
                      errors,
                      setFieldValue,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values,
                      dirty,
                      isValid
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                fullWidth
                                label="Driver"
                                data-auto-id="driverTextField"
                                name="userId"
                                margin="normal"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                select
                                value={values.userId}
                                variant="outlined"
                                error={Boolean(touched.userId && errors.userId)}
                                helperText={touched.userId && errors.userId}
                              >
                                {allStaffs.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    <ListItemText primary={option.name || option.email} secondary={option.extDriverId} />
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              {
                                Number(values?.extCompanyId ?? 0) === companyProfile?.id && (
                                  <TextField
                                    fullWidth
                                    label="Breach Calculated Using"
                                    data-auto-id="breachCalcUsingTextField"
                                    name="breachCalculatedUsing"
                                    margin="normal"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    select
                                    value={values.breachCalculatedUsing}
                                    variant="outlined"
                                    error={Boolean(touched.breachCalculatedUsing && errors.breachCalculatedUsing)}
                                    helperText={touched.breachCalculatedUsing && errors.breachCalculatedUsing}
                                  >
                                    {breachCalculationTypes.map((option) => (
                                      <MenuItem key={option.id} value={option.id.toString()}>
                                        <ListItemText primary={option.value} />
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )
                              }
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                fullWidth
                                label="Vehicle"
                                data-auto-id="vehicleTextField"
                                name="vehicleId"
                                margin="normal"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                select
                                value={values.vehicleId}
                                variant="outlined"
                                error={Boolean(touched.vehicleId && errors.vehicleId)}
                                helperText={touched.vehicleId && errors.vehicleId}
                              >
                                <MenuItem key="0" value="0">
                                  <ListItemText primary="- Rego Not Listed -" />
                                </MenuItem>
                                {allVehicles.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    <div>
                                      <ListItemText primary={option.registrationNumber} />
                                      {option.maxCapacityInKgs != null && (
                                        <Typography variant="body2" style={{ fontSize: '0.8rem', color: 'gray' }}>
                                          Maximum Payload is <span style={{ color: 'black', fontWeight: 'bold' }}>{option.maxCapacityInKgs}</span> Kgs
                                        </Typography>
                                      )}
                                    </div>
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            {
                              values.vehicleId === 0 && (
                                <Grid
                                  item
                                  md={6}
                                  xs={12}
                                >
                                  <TextField
                                    error={Boolean(touched.unlistedVehicleRego && errors.unlistedVehicleRego)}
                                    fullWidth
                                    helperText={touched.unlistedVehicleRego && errors.unlistedVehicleRego}
                                    label="Not Listed Vehicle Rego"
                                    data-auto-id="notlistedVehicleRegoTextField"
                                    margin="normal"
                                    name="unlistedVehicleRego"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.unlistedVehicleRego}
                                    variant="outlined"
                                    required
                                  />
                                </Grid>
                              )
                            }
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                fullWidth
                                label="Store/Jobsite"
                                name="jobsiteId"
                                data-auto-id="jobsiteTextField"
                                margin="normal"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                select
                                value={values.jobsiteId}
                                variant="outlined"
                                error={Boolean(touched.jobsiteId && errors.jobsiteId)}
                                helperText={touched.jobsiteId && errors.jobsiteId}
                              >
                                {/* <MenuItem key="0" value="0">
                                  <ListItemText primary="- Jobsite Not Listed -" />
                                </MenuItem> */}
                                {allStores.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    <ListItemText primary={getJobSiteName(option)} />
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            {
                              values.jobsiteId === '0' && (
                                <>
                                  <Grid
                                    item
                                    md={6}
                                    xs={12}
                                  >
                                    <TextField
                                      error={Boolean(touched.jobsiteName && errors.jobsiteName)}
                                      fullWidth
                                      helperText={touched.jobsiteName && errors.jobsiteName}
                                      label="Jobsite Name"
                                      margin="normal"
                                      name="jobsiteName"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.jobsiteName}
                                      variant="outlined"
                                      InputLabelProps={{ shrink: true }}
                                      required
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={6}
                                    xs={12}
                                  >
                                    <TextField
                                      error={Boolean(touched.jobsiteStreet && errors.jobsiteStreet)}
                                      fullWidth
                                      helperText={touched.jobsiteStreet && errors.jobsiteStreet}
                                      label="Jobsite Street"
                                      margin="normal"
                                      name="jobsiteStreet"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.jobsiteStreet}
                                      variant="outlined"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={6}
                                    xs={12}
                                  >
                                    <TextField
                                      error={Boolean(touched.jobsiteSuburb && errors.jobsiteSuburb)}
                                      fullWidth
                                      helperText={touched.jobsiteSuburb && errors.jobsiteSuburb}
                                      label="Jobsite Suburb"
                                      margin="normal"
                                      name="jobsiteSuburb"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.jobsiteSuburb}
                                      variant="outlined"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={6}
                                    xs={12}
                                  >
                                    <TextField
                                      error={Boolean(touched.jobsiteAreaCode && errors.jobsiteAreaCode)}
                                      fullWidth
                                      helperText={touched.jobsiteAreaCode && errors.jobsiteAreaCode}
                                      label="Jobsite Post Code"
                                      margin="normal"
                                      name="jobsiteAreaCode"
                                      type="number"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.jobsiteAreaCode}
                                      variant="outlined"
                                      InputLabelProps={{ shrink: true }}
                                      required
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={6}
                                    xs={12}
                                  >
                                    <TextField
                                      fullWidth
                                      label="Jobsite State"
                                      name="jobsiteStateId"
                                      margin="normal"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      required
                                      select
                                      InputLabelProps={{ shrink: true }}
                                      value={values.jobsiteStateId}
                                      variant="outlined"
                                      error={Boolean(touched.jobsiteStateId && errors.jobsiteStateId)}
                                      helperText={touched.jobsiteStateId && errors.jobsiteStateId}
                                    >
                                      {allStates.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                          <ListItemText primary={option.value} />
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                </>
                              )
                            }
                            <Grid
                              item
                              xs={12}
                            >
                              <Divider />
                            </Grid>
                            <Grid
                              item
                              md={2}
                              xs={4}
                            >
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  inputFormat="dd-MM-yyyy"
                                  label="Scheduled Start date"
                                  value={values.scheduledStartDate}
                                  onChange={(newValue) => setFieldValue('scheduledStartDate', formatInputDate(newValue, true))}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      error={Boolean(touched.scheduledStartDate && errors.scheduledStartDate)}
                                      fullWidth
                                      helperText={touched.scheduledStartDate && errors.scheduledStartDate}
                                      margin="normal"
                                      name="scheduledStartDate"
                                      variant="outlined"
                                      InputLabelProps={{ shrink: true }}
                                      required
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid
                              item
                              md={2}
                              xs={4}
                            >
                              <TextField
                                fullWidth
                                label="Hour"
                                margin="normal"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                select
                                InputLabelProps={{ shrink: true }}
                                value={values.scheduledStartHour}
                                name="scheduledStartHour"
                                variant="outlined"
                                error={Boolean(touched.scheduledStartHour && errors.scheduledStartHour)}
                                helperText={touched.scheduledStartHour && errors.scheduledStartHour}
                                SelectProps={{ native: true }}
                              >
                                {Array.from(Array(24).keys()).map((option) => (
                                  <option
                                    key={option}
                                    value={option}
                                  >
                                    {String(option).padStart(2, '0')}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              md={2}
                              xs={4}
                            >
                              <TextField
                                fullWidth
                                label="Minute"
                                margin="normal"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                select
                                InputLabelProps={{ shrink: true }}
                                value={values.scheduledStartMinute}
                                name="scheduledStartMinute"
                                variant="outlined"
                                error={Boolean(touched.scheduledStartMinute && errors.scheduledStartMinute)}
                                helperText={touched.scheduledStartMinute && errors.scheduledStartMinute}
                                SelectProps={{ native: true }}
                              >
                                {Array.from(Array(60).keys()).map((option) => (
                                  <option
                                    key={option}
                                    value={option}
                                  >
                                    {String(option).padStart(2, '0')}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              md={2}
                              xs={4}
                            >
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  inputFormat="dd-MM-yyyy"
                                  label="Scheduled End date"
                                  value={values.scheduledEndDate}
                                  onChange={(newValue) => setFieldValue('scheduledEndDate', formatInputDate(newValue, true))}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      error={Boolean(touched.scheduledEndDate && errors.scheduledEndDate)}
                                      fullWidth
                                      helperText={touched.scheduledEndDate && errors.scheduledEndDate}
                                      margin="normal"
                                      name="scheduledEndDate"
                                      variant="outlined"
                                      InputLabelProps={{ shrink: true }}
                                      required
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid
                              item
                              md={2}
                              xs={4}
                            >
                              <TextField
                                fullWidth
                                label="Hour"
                                margin="normal"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                select
                                InputLabelProps={{ shrink: true }}
                                value={values.scheduledEndHour}
                                name="scheduledEndHour"
                                variant="outlined"
                                error={Boolean(touched.scheduledEndHour && errors.scheduledEndHour)}
                                helperText={touched.scheduledEndHour && errors.scheduledEndHour}
                                SelectProps={{ native: true }}
                              >
                                {Array.from(Array(24).keys()).map((option) => (
                                  <option
                                    key={option}
                                    value={option}
                                  >
                                    {String(option).padStart(2, '0')}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              md={2}
                              xs={4}
                            >
                              <TextField
                                fullWidth
                                label="Minute"
                                margin="normal"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                select
                                InputLabelProps={{ shrink: true }}
                                value={values.scheduledEndMinute}
                                name="scheduledEndMinute"
                                variant="outlined"
                                error={Boolean(touched.scheduledEndMinute && errors.scheduledEndMinute)}
                                helperText={touched.scheduledEndMinute && errors.scheduledEndMinute}
                                SelectProps={{ native: true }}
                              >
                                {Array.from(Array(60).keys()).map((option) => (
                                  <option
                                    key={option}
                                    value={option}
                                  >
                                    {String(option).padStart(2, '0')}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.scheduledWeight && errors.scheduledWeight)}
                                fullWidth
                                helperText={touched.scheduledWeight && errors.scheduledWeight}
                                label="Scheduled Weight"
                                data-auto-id="scheduledWeightTextField"
                                margin="normal"
                                name="scheduledWeight"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.scheduledWeight}
                                variant="outlined"
                                type="number"
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.scheduledKms && errors.scheduledKms)}
                                fullWidth
                                helperText={touched.scheduledKms && errors.scheduledKms}
                                label="Scheduled Kms"
                                data-auto-id="scheduledKmsTextField"
                                margin="normal"
                                name="scheduledKms"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.scheduledKms}
                                // value={scheduledKm}
                                variant="outlined"
                                type="number"
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.scheduledDrops && errors.scheduledDrops)}
                                fullWidth
                                helperText={touched.scheduledDrops && errors.scheduledDrops}
                                label="Scheduled Drops"
                                data-auto-id="scheduledDropsTextField"
                                margin="normal"
                                name="scheduledDrops"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.scheduledDrops}
                                variant="outlined"
                                type="number"
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.scheduledTotalWorkHours && errors.scheduledTotalWorkHours)}
                                fullWidth
                                disabled
                                helperText={touched.scheduledTotalWorkHours && errors.scheduledTotalWorkHours}
                                label="Scheduled Total Work Hours"
                                margin="normal"
                                name="scheduledTotalWorkHours"
                                data-auto-id="scheduledTotalWorkHoursTextField"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={calculateScheduledTotalWorkHours(values)}
                                variant="outlined"
                                type="number"
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.dock && errors.dock)}
                                fullWidth
                                helperText={touched.dock && errors.dock}
                                label="Dock"
                                data-auto-id="dockTextField"
                                margin="normal"
                                name="dock"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.dock}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                fullWidth
                                label="External Company"
                                data-auto-id="extCompanyIdTextField"
                                margin="normal"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                select
                                InputLabelProps={{ shrink: true }}
                                value={values.extCompanyId} // Set default value to the ID of the first item
                                name="extCompanyId"
                                variant="outlined"
                                error={Boolean(touched.extCompanyId && errors.extCompanyId)}
                                helperText={touched.extCompanyId && errors.extCompanyId}
                              >
                                {/* <MenuItem value={companyProfile?.id}>
                                  <ListItemText primary={`${companyProfile?.name} (current)`} />
                                </MenuItem> */}
                                {externalCompanies.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    <ListItemText primary={option.name} />
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.instructions && errors.instructions)}
                                fullWidth
                                helperText={touched.instructions && errors.instructions}
                                label="Instructions"
                                data-auto-id="instructionsTextField"
                                margin="normal"
                                name="instructions"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.instructions}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                multiline
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button sx={{ marginRight: 2 }} color="secondary" onClick={back} variant="outlined"
                            data-auto-id="cancelButton"
                          >
                            Cancel
                          </Button>
                          <SubmitFormButton
                            dirty={dirty}
                            isValid={isValid}
                            editMode={Boolean(shiftId)}
                            onConfirm={handleSubmit}
                          />
                        </CardActions>
                      </form>
                    )}
                  </Formik>
                )
              }
            </CardContent>
          </Card>
        </Container>
      </Box>
      <Dialog
        open={selectCompanyModal}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            setSelectCompanyModal(false);
          }
        }}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Shift for</DialogTitle>
        <Formik
          initialValues={{
            companyId: extCompanyId,
          }}
          validationSchema={Yup.object().shape({
            companyId: Yup.number().required('Company is required')
          })}
          onSubmit={(values) => {
            setExtCompanyId(values.companyId);
            setSelectCompanyModal(false);
          }}
        >
          {
            ({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              dirty,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <TextField
                    fullWidth
                    label="Company"
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    select
                    InputLabelProps={{ shrink: true }}
                    value={values.companyId}
                    name="companyId"
                    variant="outlined"
                    error={Boolean(touched.companyId && errors.companyId)}
                    helperText={touched.companyId && errors.companyId}
                  >
                    {/* <MenuItem value={companyProfile?.id}>
                      <ListItemText primary={`${companyProfile?.name} (current)`} />
                    </MenuItem> */}
                    {externalCompanies.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </TextField>
                </DialogContent>
                <DialogActions>
                  <Button sx={{ marginRight: 2 }} color="secondary" onClick={back} variant="outlined">
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit" color="primary" disabled={!dirty || !isValid}>
                    Select
                  </Button>
                </DialogActions>
              </form>
            )
          }
        </Formik>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyProfile: state.profile.company
});

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditShift);
