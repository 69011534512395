import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, ListItemText, MenuItem, FormControlLabel, Checkbox, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, Grid, CardContent, Card, CardHeader, Divider, Hidden, Toolbar } from '@mui/material';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ShiftsService from '../../services/ShiftsService';
import { hide, show } from '../../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage, formatInputDate, convertToLocalDate, formatDateToLongDate } from '../../utils/common';
import EnumService from '../../services/EnumService';
import FormattedShift from '../../models/FormattedShift';
import { EnumRes, ImportFromGoogleSheetToUiShiftRes, ScheduledShiftReq } from '../../proxy/proxy';
import Table from '../Table';
import MobileDataList from '../MobileDataList';

interface Props {
  open: boolean,
  handleModalClose: (data: any) => any,
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const FetchShifts: React.FC<Props> = ({
  open,
  handleModalClose,
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const [allStates, setAllStates] = useState<Array<EnumRes>>([]);
  const [shiftData, setShiftData] = useState<FormattedShift>();
  const [fetchedShifts, setFetchedShifts] = useState<Array<ImportFromGoogleSheetToUiShiftRes>>([]);
  const [deleteShiftIndex, setDeleteShiftIndex] = useState<number | null>(null);

  useEffect(() => {
    EnumService.getEnums('states').then((states) => {
      setAllStates(states);
    }).catch();
  }, []);

  const processFetchedShifts = (shifts: any) =>
    shifts.map((shift: any, index: number) => ({
      ...shift,
      id: index
    }));

  const fetchShifts = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.importFromGoogleSheetToUi({
      stateId: Number(values.stateId),
      startDateUtc: convertToLocalDate(values.startTimeLocal),
      endDateUtc: convertToLocalDate(values.startTimeLocal),
      instructions: values.instructions,
      enforceState: values.enforceState
    })
      .then((result) => {
        showAlert(formatSuccessMessage('Shifts successfully fetched from Google Sheets'));
        const processedShifts = processFetchedShifts(result);
        setFetchedShifts(processedShifts);
        console.log(result);
        // handleModalClose(result);
      }).catch((err: any) => {
        console.log(err);
        // showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  useEffect(() => {
    clearAllAlerts();
  }, []);

  const handleDeleteShift = (index: number) => {
    setDeleteShiftIndex(index);
  };

  const handleConfirmDelete = () => {
    if (deleteShiftIndex !== null) {
      const updatedFetchedShifts = fetchedShifts.filter((_, index) => index !== deleteShiftIndex);
      setFetchedShifts(updatedFetchedShifts);
      setDeleteShiftIndex(null);
    }
  };

  // Columns definition
  const columns = [
    {
      field: 'user.firstName',
      headerName: 'Driver Name',
      flex: 1,
      renderCell: (params: { row: ImportFromGoogleSheetToUiShiftRes }) => {
        const { row } = params; // Explicitly use 'row' here
        return <span>{row.user?.firstName || 'N/A'}</span>;
      },
    },
    {
      field: 'scheduledShiftReq.startTimeLocal',
      headerName: 'Scheduled Start',
      flex: 1,
      type: 'date',
      renderCell: (params: { row: ImportFromGoogleSheetToUiShiftRes }) => {
        const { row } = params; // Explicitly use 'row' here
        return (
          <Tooltip title={row.scheduledShiftReq?.startTimeLocal || 'N/A'}>
            <span>{row.scheduledShiftReq?.startTimeLocal || 'N/A'}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'scheduledShiftReq.endTimeLocal',
      headerName: 'Scheduled End',
      flex: 1,
      type: 'date',
      renderCell: (params: { row: ImportFromGoogleSheetToUiShiftRes }) => {
        const { row } = params; // Explicitly access 'row' here
        return (
          <Tooltip title={row.scheduledShiftReq?.endTimeLocal || 'N/A'}>
            <span>{row.scheduledShiftReq?.endTimeLocal || 'N/A'}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'scheduledShiftReq.routeId',
      headerName: 'Route ID',
      flex: 1,
      renderCell: (params: { row: ImportFromGoogleSheetToUiShiftRes }) => {
        const { row } = params; // Explicitly access 'row' here
        return (
          <Tooltip title={row.scheduledShiftReq?.routeId || 'N/A'}>
            <span>{row.scheduledShiftReq?.routeId || 'N/A'}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'scheduledShiftReq.instructions',
      headerName: 'Instructions',
      flex: 2,
      renderCell: (params: { row: ImportFromGoogleSheetToUiShiftRes }) => {
        const { row } = params;
        return (
          <Tooltip title={row.scheduledShiftReq?.instructions || 'N/A'}>
            <span>{row.scheduledShiftReq?.instructions || 'N/A'}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: 150,
      renderCell: (params: { row: ImportFromGoogleSheetToUiShiftRes }) => {
        const { row } = params;
        const index = fetchedShifts.findIndex((shift) => shift.id === row.id);
        return (
          <>
            {deleteShiftIndex !== index ? (
              <Button
                onClick={() => handleDeleteShift(index)}
                color="error"
                variant="contained"
                size="small"
              >
                Delete
              </Button>
            ) : (
              <Button
                onClick={handleConfirmDelete}
                color="success"
                variant="contained"
                size="small"
              >
                Confirm Delete
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const renderEachMobileCard = (row: ImportFromGoogleSheetToUiShiftRes, index: number) => (
    <Card key={row.scheduledShiftReq?.routeId} sx={{ marginBottom: '10px' }} variant="outlined">
      <CardHeader
        title={row.user?.firstName || 'Driver Name'} // Use first name of the driver if available
        subheader={`Route ID: ${row.scheduledShiftReq?.routeId || 'N/A'}`} // Fallback to 'N/A' if routeId is missing
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">
              <b>Scheduled Start:</b> {row.scheduledShiftReq?.startTimeLocal || 'N/A'} {/* Fallback to 'N/A' */}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <b>Scheduled End:</b> {row.scheduledShiftReq?.endTimeLocal || 'N/A'} {/* Fallback to 'N/A' */}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <b>Instructions:</b> {row.scheduledShiftReq?.instructions || 'N/A'} {/* Fallback to 'N/A' */}
            </Typography>
          </Grid>
        </Grid>
        <Button
          sx={{ marginTop: 2 }}
          onClick={deleteShiftIndex === row.scheduledShiftReq?.routeId ? handleConfirmDelete : () => handleDeleteShift(index)}
          color={deleteShiftIndex === row.scheduledShiftReq?.routeId ? 'success' : 'error'}
          variant="contained"
          size="small"
        >
          {deleteShiftIndex === row.scheduledShiftReq?.routeId ? 'Confirm Delete' : 'Delete'}
        </Button>
      </CardContent>
    </Card>
  );

  const dispatchBulkSchedule = () => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.scheduleBulk(
      false,
      fetchedShifts
        .map((x) => x.scheduledShiftReq || {})
    )
      .then((result) => {
        showAlert(formatSuccessMessage('Shifts successfully dispatched from Google Sheets'));
        handleModalClose(result);
      }).catch((err: any) => {
        console.log(err);
        // showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleModalClose(null)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={fetchedShifts.length > 0 ? 'lg' : 'sm'}
        sx={{
          '& .MuiDialog-paper': {
            width: '90%', // Force it to take 90% of the screen width
          },
        }}
      >
        <DialogTitle id="form-dialog-title">Fetch Shifts</DialogTitle>
        { fetchedShifts.length === 0 && (
          <Formik
            initialValues={{
              startTimeLocal: '',
              endTimeLocal: '',
              stateId: '',
              enforceState: false,
              instructions: shiftData?.instructions || '',
            }}
            validationSchema={Yup.object().shape({
              startTimeLocal: Yup.date().required('Start Date is required'),
              endTimeLocal: Yup.date(),
              stateId: Yup.number().required('State is required'),
              instructions: Yup.string()
            })}
            onSubmit={fetchShifts}
          >
            {
              ({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                isValid,
                dirty
              }) => (
                <form onSubmit={handleSubmit}>
                  <DialogContent>
                    <TextField
                      error={Boolean(touched.stateId && errors.stateId)}
                      fullWidth
                      helperText={touched.stateId && errors.stateId}
                      margin="normal"
                      label="State"
                      name="stateId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      value={values.stateId}
                    >
                      {allStates.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          <ListItemText primary={option.value} />
                        </MenuItem>
                      ))}
                    </TextField>
                    <FormControlLabel
                      control={
                        (
                          <Checkbox
                            name="enforceState"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            color="primary"
                          />
                        )
                      }
                      label="Enforce state"
                    />
                    <TextField
                      error={Boolean(touched.startTimeLocal && errors.startTimeLocal)}
                      fullWidth
                      helperText={touched.startTimeLocal && errors.startTimeLocal}
                      label="Date"
                      margin="normal"
                      name="startTimeLocal"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.startTimeLocal}
                      variant="outlined"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                    <TextField
                      error={Boolean(touched.instructions && errors.instructions)}
                      fullWidth
                      helperText={touched.instructions && errors.instructions}
                      label="Instructions"
                      data-auto-id="instructionsTextField"
                      margin="normal"
                      name="instructions"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.instructions}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      multiline
                    />
                  </DialogContent>
                  <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button
                      onClick={() => handleModalClose(null)}
                      color="primary"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      type="submit"
                      disabled={!dirty || !isValid}
                    >
                      Fetch
                    </Button>
                  </DialogActions>
                </form>
              )
            }
          </Formik>
        )}
        {fetchedShifts.length > 0 && (
          <div style={{ margin: '20px' }}>
            <Hidden mdDown>
              <Paper>
                <Table
                  rows={fetchedShifts}
                  columns={columns}
                  totalRows={fetchedShifts.length}
                  page={0}
                  onPageChange={function (prop: any) {
                    throw new Error('Function not implemented.');
                  }}
                  sortModel={undefined}
                  onSortModelChange={function (prop: any) {
                    throw new Error('Function not implemented.');
                  }}
                />
              </Paper>
            </Hidden>
            <Hidden mdUp>
              <MobileDataList
                rows={fetchedShifts}
                renderEachRow={renderEachMobileCard}
                totalRows={fetchedShifts.length}
                page={0}
                onPageChange={function (prop: any) {
                  throw new Error('Function not implemented.');
                }}
              />
            </Hidden>

            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button
                onClick={() => handleModalClose(null)}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => dispatchBulkSchedule()}
              >
                Dispatch
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(FetchShifts);
